/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "@angular/material/theming";
@include mat.core();
// Por defecto cargamos el tema de thermal
@import "theme.thermal.scss";
@include mat.all-component-themes($thermal-theme);

/* ===============
THERMAL THEME
=============== */

.thermal-theme {
    @import "theme.thermal.scss";
    @include mat.all-component-themes($thermal-theme);
    .primary-color {
        color: mat.get-color-from-palette($primary-cust, 500) !important;
    }
    .accent-color {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
    }
    button.stroked {
        background-color: mat.get-color-from-palette($secondary-cust, 500) !important;
        color: $white !important;
        border: mat.get-color-from-palette($secondary-cust, 500) 1px solid;
    }
    footer {
        div.logged {
            border-color: mat.get-color-from-palette($secondary-cust, 500);
        }
    }

    .mat-table td.action .mat-icon,
    .mat-table td.action .mat-icon:hover {
        opacity: inherit;
    }

    .linkApp > a {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
        font-weight: bold;
    }
}

/* ===============
CALIBRATES THEME
=============== */

.calibrates-theme {
    @import "theme.calibrates.scss";
    @include mat.all-component-themes($calibrate-theme);
    .primary-color {
        color: mat.get-color-from-palette($primary-cust, 500) !important;
    }
    .accent-color {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
    }
    button.stroked {
        background-color: $white !important;
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
        border: mat.get-color-from-palette($secondary-cust, 500) 1px solid;
    }
    footer {
        div.logged {
            border-color: mat.get-color-from-palette($secondary-cust, 500);
        }
    }

    .mat-table td.action .mat-icon,
    .mat-table td.action .mat-icon:hover {
        opacity: inherit;
    }

    .linkApp > a {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
        font-weight: bold;
    }
}

/* ===============
AIR THEME
=============== */

.air-theme {
    @import "theme.air.scss";
    @include mat.all-component-themes($air-theme);
    .primary-color {
        color: mat.get-color-from-palette($primary-cust, 500) !important;
    }
    .accent-color {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
    }
    button.stroked {
        background-color: mat.get-color-from-palette($secondary-cust, 500) !important;
        color: $white !important;
        border: mat.get-color-from-palette($secondary-cust, 500) 1px solid;
    }
    footer {
        div.logged {
            border-color: mat.get-color-from-palette($secondary-cust, 500);
        }
    }

    .mat-table td.action .mat-icon,
    .mat-table td.action .mat-icon:hover {
        opacity: inherit;
    }

    .linkApp > a {
        color: mat.get-color-from-palette($secondary-cust, 500) !important;
        font-weight: bold;
    }
    
}

/* ===============
THERMAL THEME
=============== */

.primary-color {
    color: mat.get-color-from-palette($primary-cust, 500) !important;
}

.accent-color {
    color: mat.get-color-from-palette($secondary-cust, 500) !important;
}

button.stroked {
    background-color: mat.get-color-from-palette($primary-cust, 500);
    color: $white;
    border: mat.get-color-from-palette($primary-cust, 500);
}

button.stroked-primary {
    background-color: mat.get-color-from-palette($primary-cust, 500) !important;
    color: white !important;
    border: mat.get-color-from-palette($primary-cust, 500) 1px solid;
}

footer {
    div.logged {
        border-color: mat.get-color-from-palette($secondary-cust, 500);
    }
}

/* ===============
FONTS
=============== */

$font-family: "IBM Plex Sans", sans-serif;
$custom-typography: mat.define-typography-config(
    $font-family: $font-family,
    $headline: mat.define-typography-level(36px, 47px, 300),
    $title: mat.define-typography-level(20px, 30px, 700),
    $button: mat.define-typography-level(16px, 20px, 700),
);
@include mat.core($custom-typography);
body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100%;
    overflow-y: hidden;
}

body {
    font-size: 1em;
}

/* ===============
COMMON
=============== */

.mat-toolbar {
    background-color: $light-gray !important;
    // border-bottom: 1px solid #ccc;
    ul li {
        list-style-type: none;
    }
}

.mat-toolbar-border {
    border-bottom: 1px solid #2345451c;
    z-index: 2;
    box-shadow: 0 0px 6px #23454573;
    position: relative;
}

.mat-raised-button {
    height: 40px;
    @include mat.elevation(1);
    &:hover {
        @include mat.elevation(6);
    }
}

.mat-card-actions {
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mat-card > .mat-card-actions:last-child {
    margin-bottom: 0 !important;
}

.form-separator {
    height: 1rem;
}

/* ===============
MAIN CONTAINER
=============== */

.main-container {
    max-width: 100rem;
    display: block;
    margin: auto;
    padding: 1rem;
    &.small-layout {
        max-width: 90rem;
    }
}

/* ===============
HEADER
=============== */

.main-menu {
    ul {
        max-width: 100%;
        padding: 0;
        .mat-icon {
            margin-right: 0;
            margin-left: 16px;
        }
        .mat-menu-item {
            color: #3C414F;
            line-height: 24px;
            white-space: normal;
        }
        .mat-menu-item .mat-icon {
            color: #3C414F;
        }
        .mat-menu-item div {
            text-transform: uppercase;
            line-height: 1.2;
            text-align: right;
            display: flex;
            align-items: center;
        }
        .mat-menu-item div .mat-icon {
            color: #3C414F;
            margin-left: 0;
        }
        .mat-badge-content {
            color: $white;
        }
    }
    ul {
        .mat-menu-item.login {
            color: $corporate-medium;
        }
        .mat-menu-item.login .mat-icon {
            color: $corporate-medium;
        }
        .mat-menu-item.login div .mat-icon {
            color: $corporate-medium;
        }
    }
}

@media screen and (max-width: 599px) {
    .main-menu {
        ul {
            .mat-icon {
                margin: 0 !important;
            }
            .mat-menu-item {
                padding: 0 0.25rem;
            }
        }
    }

    .size-logo-menu-start {
        width: 70% !important;
    }
}

/* ===============
FOOTER
=============== */

footer {
    .login {
        padding: 2rem 0 1rem 0;
        border-bottom: solid 5px $corporate-medium;
    }
}

footer {
    .logged {
        padding: 2rem 0 1rem 0;
        border-bottom: solid 5px;
    }
}

/* ===============
LOGIN PAGE
=============== */

/* Logo Login Page */

.main-container {
    .appImageContainer {
        max-width: 224px;
        margin: 0 auto;
        display: block;
        margin-bottom: 36px;
        .appImage {
            max-width: 100%;
            height: auto;
        }
    }
}

@media (max-width: 767.98px) {
    .main-container {
        .appImageContainer {
            max-width: 160px;
            margin-bottom: 24px;
        }
    }
}

/* Login box */

.loginBox {
    h2 {
        margin: 0 auto;
        display: table;
        padding: 0 0 32px 0;
    }
    .mat-card-actions {
        margin: 0 auto !important;
        display: table;
    }
}

/* Under Card Link */

.under-card-link {
    padding: 8px;
    margin: 0 auto;
    display: table;
    margin-top: 0.5rem;
}

/* ===============
LIST
=============== */

.toolbar-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .toolbar-list-title {
        flex: 1 auto;
        margin: 0.5rem 0;
        img {
            padding-right: 0.5rem;
        }
        h1 {
            margin: 0;
            line-height: 1;
        }
        .breadcrumbs a {
            color: $corporate-medium;
        }
    }
    .toolbar-list-actions,
    .toolbar-back-button {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin: 0.5rem 0;
        button {
            height: 3rem;
            margin: 0 0.5rem;
            padding: 0 0.5rem;
            span {
                display: flex;
                align-items: center;
                .mat-icon {
                    margin-right: 0.25rem;
                }
            }
        }
        a.mat-button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
        }
    }
}

@media (max-width: 959px) {
    .toolbar-list {
        flex-wrap: wrap;
        .toolbar-list-actions {
            justify-content: space-between;
            .mat-raised-button {
                white-space: normal;
                line-height: 1.2;
                min-width: auto;
                padding: 0 0.5rem;
                flex-grow: 1;
                .mat-button-wrapper {
                    justify-content: center;
                }
            }
        }
        h1 {
            font: 300 32px/42px "IBM Plex Sans", sans-serif;
        }
        .toolbar-back-button,
        .toolbar-list-actions {
            margin: 0.5rem -0.5rem;
            a.mat-button {
                height: auto;
                padding-right: 0.5rem;
            }
        }
        .toolbar-list-title {
            width: calc(100% - 100px);
        }
        .toolbar-list-actions {
            order: 3;
            width: calc(100% + 1rem);
        }
    }
}

/* ===============
FILTER
=============== */

.thermal-theme .filter-container,
.calibrates-theme .filter-container,
.air-theme .filter-container,
.filter-container {
    form {
        padding-bottom: 1rem;
    }
    .mat-expansion-panel {
        button {
            height: 40px;
        }
        .mat-expansion-panel-header-title {
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.thermal-theme .mat-expansion-panel .mat-expansion-panel,
.calibrates-theme .mat-expansion-panel .mat-expansion-panel,
.air-theme .mat-expansion-panel .mat-expansion-panel,
.mat-expansion-panel .mat-expansion-panel {
    box-shadow: none;
    padding-bottom: 1rem;
    .mat-expansion-panel-header-title {
        font-size: 14px;
        line-height: 18px;
    }
    .mat-expansion-panel-header {
        padding: 0;
        .mat-content {
            flex: none;
        }
    }
    .mat-expansion-panel-body {
        padding-left: 0;
        padding-right: 0;
    }
    .mat-expansion-panel-header:not([aria-disabled="true"]):hover,
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
        background-color: $white;
    }
}

.thermal-theme .mat-expansion-panel:not(.mat-expanded),
.calibrates-theme .mat-expansion-panel:not(.mat-expanded),
.air-theme .mat-expansion-panel:not(.mat-expanded),
.mat-expansion-panel:not(.mat-expanded) {
    .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-keyboard-focused,
    .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-program-focused,
    .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
        background-color: $white;
    }
}

/* ===============
LIST FOOTER
=============== */

.table-footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5em;
    .actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        button {
            margin-right: 1em;
        }
        a {
            padding: 0.5em 0;
        }
    }
    .paginator {
        display: flex;
        justify-content: flex-end;
        .mat-paginator {
            background-color: transparent;
            color: $medium-gray;
            .mat-paginator-container {
                min-height: auto;
                padding: 0;
            }
        }
        .mat-paginator-page-size-label {
            margin-left: 0;
        }
        .mat-paginator-range-label {
            margin: 0 0.5rem;
        }
        a.back-to-top {
            padding: 0.5rem 0;
            text-decoration: none;
            margin-top: 0.5rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .table-footer-container {
        .actions {
            padding-top: 1em;
        }
        .mat-paginator-container {
            justify-content: center;
            margin-top: 0.5rem;
            .mat-paginator-page-size {
                margin-right: 0;
            }
        }
        flex-direction: column-reverse;
        a.back-to-top {
            min-width: 80px;
        }
    }
}

/* ===============
FORMS
=============== */

.datepicker {
    width: calc(100% - 14px);
}

.campoFormulario {
    width: calc(100% - 14px);
}

/* ===============
FORMS NEW AND EDIT
=============== */

.form-block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    padding: 2em 0;
    border-bottom: #d8d8d8 solid 1px;
    h2 {
        font-size: 1.2em;
        padding-top: 0.6em;
    }
    .form-icon {
        display: flex;
        justify-content: center;
        padding-top: 1em;
    }
    .side-block,
    .form-icon {
        width: 12%;
        min-width: 100px;
    }
    .form-content {
        display: flex;
        flex-wrap: wrap;
        width: 76%;
    }
    .table-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 88%;
    }
    .top-padding {
        padding-top: 1em;
    }
    .top-padding-half {
        padding-top: 0.5em;
    }
}

.companyLogo {
    max-height: 10rem;
    max-width: 10rem;
    border: $corporate-medium solid 1px;
    border-radius: 0.5em;
}

@media (max-width: 767.98px) {
    .form-block {
        flex-direction: column;
        .side-block {
            display: none;
        }
        .form-icon,
        .table-content,
        .form-content {
            width: 100%;
        }
    }
}

@media (max-width: 599px) {
    .form-footer-action {
        .mat-card-actions .mat-raised-button,
        .mat-card-actions .mat-button {
            white-space: normal;
            line-height: 1.2;
            min-width: auto;
            padding: 0 0.5rem;
            flex-grow: 1;
            height: 3em;
            margin: 0 0.5em 0 0;
            .mat-button-wrapper {
                justify-content: center;
            }
            .mat-icon {
                display: none;
            }
        }
    }
}

/* ===============
HOME & LANDING
=============== */

/* Block Hightlights */

.block-highlights {
    margin: 6vh -1rem 0rem -1rem;
    flex-wrap: wrap;
    .card {
        text-align: center;
        flex: 1 1 auto;
        margin: 1rem;
        padding: 0;
        .mat-card-content {
            padding: 3rem 2rem 1rem 2rem;
            position: relative;
        }
        .action-floating {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 1rem 0 1rem;
            span {
                margin-left: 0.25rem;
            }
            .mat-icon {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
        .new-badge {
            left: 0;
            transition: color 250ms ease-in-out;
            &:hover {
                color: $corporate-dark;
            }
        }
        .info-badge {
            right: 0;
        }
        .icon {
            width: 3rem;
            height: auto;
            display: block;
            margin: 0 auto 1rem auto;
        }
        .title {
            font-size: 20px;
            line-height: 1.5;
            width: 220px;
            display: block;
            margin: auto;
        }
    }
    .disabledCard {
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        color: lightgray;
    }
    &.see-all {
        margin: -2rem -1rem 2rem -1rem;
        .mat-card-content {
            padding: 1rem;
        }
    }
}

@media (max-width: 767.98px) {
    .block-highlights {
        margin: 2rem -0.5rem 2rem -0.5rem;
        .card {
            margin: 0.5rem;
            .mat-card-content {
                padding: 3rem 1rem 1rem 1rem;
                &.see-all {
                    padding: 2rem 1rem;
                }
            }
        }
        &.see-all {
            margin: -2rem -0.5rem 2rem -0.5rem;
            .mat-card-content {
                padding: 1rem;
            }
        }
    }
}

/* ===============
TABLE
=============== */

.table-container {
    width: 100%;
    overflow: auto;
}

.table-spaced {
    padding-top: 2em;
}

.thermal-theme .mat-table,
.calibrates-theme .mat-table,
.air-theme .mat-table,
.mat-table {
    width: 100%;
    background-color: transparent;
    tr.mat-footer-row,
    tr.mat-row {
        height: 62px;
    }
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
        padding-left: 1rem;
    }
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type {
        padding-right: 1rem;
        min-width: 0;
    }
    th.mat-header-cell {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 700;
        min-width: 6rem;
        padding-right: 1rem;
        button {
            text-align: left;
        }
    }
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type {
        min-width: 0;
    }
    td.mat-cell {
        color: $medium-gray;
        max-width: 15rem;
        padding-right: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    td.action {
        padding-right: 0;
        .mat-icon {
            opacity: 75%;
            &:hover {
                opacity: 100%;
            }
        }
    }
    td.deactivate {
        color: $error;
    }
    td.warning {
        color: $warning;
    }
    .deactivate {
        color: $error;
    }
    .activate {
        color: $success;
    }
    td.to-complete {
        color: #1cb1fe;
    }
    .to-complete {
        color: #1cb1fe;
    }
    th.mat-table-sticky {
        background-color: $light-gray;
    }
    td.mat-table-sticky {
        .mat-icon-button {
            border-left-width: 20px;
            border-left-style: solid;
            border-image: linear-gradient(to left, $light-gray, rgba(0, 0, 0, 0)) 1 100%;
            .mat-icon {
                background-color: $light-gray;
                width: 40px;
                height: 100%;
                line-height: 30px;
            }
        }
    }
}

.mat-card {
    .in-table-action {
        margin-top: 1.5em;
    }
    .mat-table {
        .mat-cell {
            background-color: $white;
        }
        th.mat-table-sticky {
            background-color: $white;
        }
        td.mat-table-sticky {
            background-color: transparent;
            .mat-icon-button {
                .mat-icon {
                    background-color: $white;
                }
            }
        }
    }
}

.moreActions {
    .mat-menu-content:not(:empty) {
        padding: 0;
    }
    button {
        color: $corporate-medium;
        display: block;
        font-size: 14px;
        font-weight: 400;
        padding: 0.5rem 1rem;
        .mat-icon {
            padding-right: 0.5rem;
        }
        .deactivate {
            color: $error;
        }
        .activate {
            color: $success;
        }
    }
}

.prefilterMessage {
    display: table;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: $medium-gray;
    padding: 1rem 0 0.5rem 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 767.98px) {
    .thermal-theme .mat-table,
    .calibrates-theme .mat-table,
    .air-theme .mat-table,
    .mat-table {
        td.mat-cell:first-of-type,
        td.mat-footer-cell:first-of-type,
        th.mat-header-cell:first-of-type {
            padding-left: 0;
        }
        td.mat-cell:last-of-type,
        td.mat-footer-cell:last-of-type,
        th.mat-header-cell:last-of-type {
            padding-right: 0;
        }
        td.mat-table-sticky {
            .mat-icon-button {
                .mat-icon {
                    width: 20px;
                }
            }
        }
    }
    .table-footer-container {
        a.back-to-top {
            padding-left: 0;
        }
    }
}

/* ===============
PROFILE
=============== */

.user-info-container {
    margin-bottom: 2rem;
    .user-pic {
        .circle {
            width: 6rem;
            height: 6rem;
            background: $white;
            border-radius: 100%;
            position: relative;
        }
        .initialLetters {
            color: $corporate-medium;
            font-weight: 700;
            font-size: 3rem;
            opacity: 0.5;
        }
        .mat-raised-button {
            position: absolute;
            bottom: 0;
            right: 0;
            min-width: 2rem;
            max-height: 2rem;
            padding: 0;
            margin: 0;
            line-height: 2rem;
            border-radius: 100%;
            .mat-icon {
                height: 18px;
                width: 18px;
                font-size: 18px;
                line-height: 1rem;
            }
        }
    }
    .user-info {
        padding-left: 2rem;
        h2 {
            font-size: 2rem;
            font-weight: normal;
            margin-bottom: 0.5rem;
            color: $corporate-dark;
        }
        p {
            color: $corporate-medium;
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
        *:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.profile {
    max-width: 20rem;
}

@media screen and (max-width: 599px) {
    .user-info-container {
        .user-pic {
            .circle {
                width: 5rem;
                height: 5rem;
            }
            .initialLetters {
                font-size: 2.5rem;
            }
        }
        .user-info {
            padding-left: 1rem;
            h2 {
                font-size: 1.5rem;
            }
            p {
                font-size: 0.875rem;
            }
        }
    }

    .block-option-highlights {
        margin: 2vh -1rem 2rem -1rem !important;
    }

    .size-logo-menu {
        width: 70% !important;
    }

    .size-img-sidebar{
        width: 40% !important;
    }
}

/* ===============
EXECUTION TEMP
=============== */

.mat-icon.opacity50 {
    opacity: 0.5;
}

/* --- */

.flexwrap {
    flex-wrap: wrap;
}

.loginBox {
    max-width: 90%;
    width: 300px;
    margin: 0 auto;
}

.card {
    margin: 0 auto;
}

.error {
    color: red;
    font-weight: bold;
}

.form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
}

.full-width {
    width: 100%;
}

.table-full-width {
    width: 100%;
    display: block !important;
}

.table-full-width > tbody {
    width: 100%;
    display: block !important;
}

/*.campoFormulario {
margin: 0 auto;
display: table;
width: 95%;
}*/

.example-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

.example-radio-button {
    margin: 5px;
}

.example-margin {
    margin: 10px;
}

.mat-card {
    padding: 10px;
}

.mat-dialog-content {
    max-height: initial !important;
}

.mat-column-delete {
    width: 30px;
}

/*@media screen and (max-width: 960px) {
.mat-table {
border: 0;
vertical-align: middle;
}

.mat-table caption {
font-size: 1em;
}

/*  Enable this to hide header
.mat-table .mat-header-cell {

    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
}
*/

/*  .mat-table .mat-row {
border-bottom: 5px solid #ddd;
display: block;
}
/*
.mat-table .mat-row:nth-child(even) {background: #CCC}
.mat-table .mat-row:nth-child(odd) {background: #FFF}
*/

/* .mat-table .mat-cell {
border-bottom: 1px solid #ddd;
display: block;
font-size: 1em;
text-align: right;
font-weight: bold;
height: 30px;
margin-bottom: 4%;
}
.mat-table .mat-cell:before {
/*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */

/*   content: attr(data-label);
float: left;
text-transform: uppercase;
font-weight: normal;

font-size: 0.85em;
}
.mat-table .mat-cell:last-child {
border-bottom: 0;
}
.mat-table .mat-cell:first-child {
margin-top: 4%;
}
}*/

.spinner-container-modal {
    background: lightgray;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    opacity: 0.7;
    position: fixed;
    top: 0;
}

mat-spinner.modal {
    position: fixed;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 9999;
}

.mat-calendar-body-label {
    visibility: hidden;
}

.profileImage {
    border-radius: 15%;
    max-height: 150px;
    max-width: 150px;
    margin: 10px;
    margin-top: 5px;
}

.tooltip {
    white-space: pre-line;
    font-size: 14px;
}

/* SIDEBAR MENU */

.mat-sidenav {
    width: 350px;
    .mat-drawer-inner-container {
        background: $white;
    }
    .mat-list-base {
        .mat-list-item {
            color: $corporate-medium;
            font-size: 0.875rem;
        }
        .mat-list-item.second-level {
            font-size: 0.875rem;
            height: 2.5rem;
            opacity: 0.5;
        }
        .mat-list-item.first-level {
            color: $corporate-medium;
            height: 3.5rem;
            font-weight: 500;
            margin-right: 1rem;
        }
        .mat-divider {
            border-top-color: $corporate-medium;
            margin: 1rem;
            max-width: 6rem;
        }
        .mat-expansion-panel {
            box-shadow: none;
            border-radius: 0;
            background: transparent !important;
            padding: 0;
            margin: 0;
            .mat-expansion-indicator::after {
                color: $corporate-medium;
            }
            .mat-expansion-panel-header {
                font-size: 1rem;
                padding: 0 1rem;
                background: transparent !important;
                .mat-content {
                    margin-right: 1rem;
                }
                .first-level {
                    font-size: 0.875rem;
                    color: $corporate-medium;
                    font-weight: 500;
                    display: flex;
                    flex: none !important;
                }
            }
            .mat-expansion-panel-body {
                padding: 0 1rem;
                background: transparent !important;
                .mat-list-item {
                    color: $corporate-medium;
                    font-size: 0.875rem;
                    height: 2rem;
                    opacity: 0.5;
                    font-weight: 500;
                }
            }
        }
    }
    .mat-nav-list .userDivToolbar {
        background: rgba(254, 254, 254, 0.1);
        color: white;
        margin-top: -8px;
        padding: 1rem;
        .user-pic {
            .circle {
                width: 4rem;
                height: 4rem;
                background: $corporate-medium;
                border-radius: 100%;
            }
            .initialLetters {
                color: $corporate-medium;
                font-weight: 700;
                font-size: 2rem;
                opacity: 0.5;
            }
        }
        .user-info {
            padding-left: 1rem;
            > * {
                margin: 0;
                font-weight: 500;
            }
            p {
                opacity: 0.5;
                font-size: 0.875rem;
            }
        }
    }
    .userDivToolbar > span {
        margin: 0 auto;
        display: table;
    }
    .picDivToolbar {
        border-radius: 15%;
        max-height: 50px;
        max-width: 50px;
        margin: 0 auto;
        display: block;
        margin-bottom: 1em;
    }
    .mat-drawer-inner-container {
        overflow-x: hidden !important;
        width: 100%;
    }
}

.tablaConBordes tr {
    height: 56px;
}

.tablaConBordes td,
.tablaConBordes th {
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.tablaConBordes th {
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
}

.tablaConBordes td {
    font-size: 14px;
}

.essayImage {
    max-height: 20em;
    max-width: 100vw;
}

.vertical-radio-group {
    display: inline-flex;
    flex-direction: column;
}

.vertical-radio-button {
    margin: 5px;
}

.unread {
    font-weight: bold;
}

tr.expand-detail-row {
    height: 0 !important;
}

.expand-detail-element {
    overflow: hidden;
}

.expanded-row {
    font-weight: normal;
}

.bigIcon {
    font-size: 100px;
}

.centerIcon {
    display: table !important;
    margin: 0 auto !important;
}

.blueIcon {
    color: #356969;
}

.grayIcon {
    color: gray;
}

.centerText {
    margin: 0 auto;
    text-align: center;
}

.errorTitle {
    font-size: xxx-large;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
}

.errorImage {
    max-height: 360px;
}

.pointerHand {
    cursor: pointer;
}

.cookie-law-wrapper .copy span {
    color: #fff;
    font-size: 15px;
}

.badgeOculto > .mat-badge-content {
    color: transparent;
}

.downloadAudit {
    color: rgba(0, 0, 0, 0.47) !important;
}

@media only screen and (min-device-width: 640px) {
    .downloadAudit {
        padding-top: 0.5em !important;
    }
}

.mayusculas {
    text-transform: uppercase;
}

.generatedDate {
    position: absolute;
    right: 5px;
    bottom: 10px;
    opacity: 0.6;
    font-size: 0.75em;
}

.mat-list-option {
    height: 1.5em !important;
}

.bold {
    font-weight: bold;
}

.tachado {
    text-decoration: line-through;
}

[hidden] {
    display: none !important;
}

.logo-header-container {
    text-align: center;
    .logo-desktop {
        width: auto;
        height: 36px;
    }
    .logo-mobile {
        width: auto;
        height: 2rem;
    }
}

.validValue {
    font-weight: bold;
    color: green;
}

.invalidValue {
    font-weight: bold;
    color: red;
}

sup {
    font-size: 10px;
}

.ck-editor__editable_inline {
    min-height: 300px;
}

.planType {
    font-size: x-large;
    text-align: center;
    display: block;
}

.paymentCard {
    width: 50%;
    margin: auto;
}

@media only screen and (max-width: 959px) {
    .paymentCard {
        width: 100%;
    }
}

.mat-table td.action .mat-icon,
.mat-table td.action .mat-icon:hover {
    opacity: inherit !important;
}

.linkApp > a {
    color: mat.get-color-from-palette($secondary-cust, 500) !important;
    font-weight: bold;
}

.toast-container.toast-top-full-width .ngx-toastr {
    margin-top: 1em;
}

.toast-success {
    background-color: #4aaa6c;
}

.toast-error {
    background-color: #bd362f;
}

.toast-info {
    background-color: #59bec9;
}

.toast-warning {
    background-color: #ea9274;
}

.placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.toast-container * {
    white-space: pre-line;
}

.button-bottom-sheet {
    padding: 5px;
    cursor: pointer;
}

.spinnerText {
    font-size: 20px;
    margin-top: 2em !important;
    color: #224545;
}

.spinnerImage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.tableEssayProtocol:hover {
    cursor: pointer;
    background: yellowgreen;
    font-weight: bold;
}

/*.mat-expansion-panel-body {
    overflow-x: auto !important;
} */

.correctedSensorIcon {
    color: #32c0f4;
}

.essayNotAccording {
    color: red !important;
}

.essayAccording {
    color: green !important;
    font-weight: bold;
}

.essayDisabled {
    text-decoration: line-through;
}

.icon-display {
    transform: scale(0.7);
}

.icon-display-plus {
    transform: scale(0.8);
}

.draganddrop-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
}

.draganddrop-list.cdk-drop-list-dragging .draganddrop-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draganddrop-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
}

.draganddrop-box:last-child {
    border: none;
}

.centerButton > span > mat-icon {
    padding-bottom: 1em !important;
}

.videoPlayer {
    min-height: 80vh;
    max-height: 85vh;
    margin: 0 auto;
    display: block;
}

input.noArrow::-webkit-outer-spin-button,
input.noArrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.noArrow[type=number] {
  -moz-appearance: textfield;
}

.block-option-highlights {
    margin: 8vh -1rem 2rem -1rem;
    flex-wrap: wrap;
    .card {
        text-align: center;
        flex: 1 1 auto;
        margin: 1rem;
        padding: 0;
        border-radius: 20px;
        .mat-card-content {
            padding: 3rem 2rem 1rem 2rem;
            position: relative;
        }
        .action-floating {
            position: absolute;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 1rem 0 1rem;
            span {
                margin-left: 0.25rem;
            }
            .mat-icon {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }
        .new-badge {
            left: 0;
            transition: color 250ms ease-in-out;
            &:hover {
                color: $corporate-dark;
            }
        }
        .info-badge {
            right: 0;
        }
        .icon {
            width: 12rem;
            height: auto;
            display: block;
            margin: 0 auto 1rem auto;
        }
        .title {
            font-size: 20px;
            line-height: 1.5;
            width: 220px;
            display: block;
            margin: auto;
        }
    }
    .disabledCard {
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        color: lightgray;
    }
    &.see-all {
        margin: -2rem -1rem 2rem -1rem;
        .mat-card-content {
            padding: 1rem;
        }
    }
}

.card-minimun-text {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    .title-minimun {
        background: #e7e7e7;
        padding: 5px 10px;
        border-radius: 13px;
        color: purple;
        font-size: 13px;
        font-weight: 500;
        width: 50%;
        margin: auto;
    }
}

/* ===============
LOGIN PAGE CLIENT
=============== */

/* Logo Login Page */

.main-container-client {
    padding: 0 8em;

    .appImageContainer-client {
        max-width: 260px;
        display: block;
        margin-bottom: 15px;
        .appImage-client {
            max-width: 100%;
            height: auto;
        }
    }

    .title {
        font-size: 7em;
        color: #1e71b7;
        font-weight: 600;
    }

    .subtitle {
        font-size: 4em;
        font-weight: 100;
        color: #1e71b7;
        line-height: 60px;
    }

    .loginBoxClient {
        padding: 40px 30px;
        border-radius: 30px;
        h2 {
            margin: 0 auto;
            display: table;
            padding: 0 0 32px 0;
        }
        .mat-card-actions {
            display: table;
    
            .btnLogin {
                background: #73c1e9;
                color: white;
            }
        }
    }
}
.app-header,
.app-header-client {
    height: 85px; 
}
.logo-header-container-client{
    max-width: 224px;
    margin: 0 auto;
    display: block;
    margin-bottom: 36px;
    .appImage {
        max-width: 100%;
        height: auto;
    }
}

.header-client {
    background-color: white;
    border-bottom: 1px solid #ccc;

    .appImage {
        width: 60%;
    }
}

.bell-icon {
    color: #CCC !important;
    font-size: 2em !important; 
    height: 35px !important; 
    width: 35px !important; 
}

// Menu Principal 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
.button-selected-app {
    border:0;
    background: $white;
    margin-bottom: 15px;
}

.button-selected-app:focus-visible  {
    background: white;
}

.button-selected-app:focus  {
    background: white;
}

.button-selected-app:hover  {
    background: white;
}

.button-selected-app:active  {
    background: white;
}

.container-menu-main {
    padding: 10px;
    text-align: center;
}

.container-menu-main:focus {
    background: $light-gray;
}

.container-menu-main:hover {
    background: $light-gray;
}

.icon-menu-item {
    width: 35px !important;
    height: 35px !important;
    margin-right: 8px !important;
    font-size: 28px !important;
}

.icon-menu-item-sidebar {
    width: 24px !important;
    height: 24px !important;
    margin-right: 10px !important;
    font-size: 20px !important;
}

// Menu principal
.mat-sidenav-main {
    width: 350px;
    .mat-drawer-inner-container {
        background: $white;
    }
    .mat-list-base {
        .mat-list-item {
            color: $corporate-medium;
        }
        .mat-list-item.second-level {
            font-size: 0.875rem;
            height: 2.5rem;
            opacity: 0.5;
        }
        .mat-list-item.first-level {
            color: $corporate-medium;
            height: 3.5rem;
            font-weight: 500;
            margin-right: 1rem;
            font-size: 0.875rem;
        }
        .mat-divider {
            border-top-color: $corporate-medium;
            margin: 1rem;
            max-width: 6rem;
        }
        .mat-expansion-panel {
            box-shadow: none;
            border-radius: 0;
            background: transparent !important;
            padding: 0;
            margin: 0;
            .mat-expansion-indicator::after {
                color: $corporate-medium;
            }
            .mat-expansion-panel-header {
                font-size: 1rem;
                padding: 0 1rem;
                background: transparent !important;
                .mat-content {
                    margin-right: 1rem;
                }
                .first-level {
                    font-size: 0.875rem;
                    color: $corporate-medium;
                    font-weight: 500;
                    display: flex;
                    flex: none !important;
                }
            }
            .mat-expansion-panel-body {
                padding: 0 1rem;
                background: transparent !important;
                .mat-list-item {
                    color: $corporate-medium;
                    font-size: 0.875rem;
                    height: 2rem;
                    opacity: 0.5;
                    font-weight: 500;
                }
            }
        }
    }
    .mat-nav-list .userDivToolbar {
        background: rgba(254, 254, 254, 0.1);
        color: white;
        margin-top: -8px;
        padding: 1rem;
        .user-pic {
            .circle {
                width: 4rem;
                height: 4rem;
                background: $corporate-medium;
                border-radius: 100%;
            }
            .initialLetters {
                color: $corporate-medium;
                font-weight: 700;
                font-size: 2rem;
                opacity: 0.5;
            }
        }
        .user-info {
            padding-left: 1rem;
            > * {
                margin: 0;
                font-weight: 500;
            }
            p {
                opacity: 0.5;
                font-size: 0.875rem;
            }
        }
    }
    .userDivToolbar > span {
        margin: 0 auto;
        display: table;
    }
    .picDivToolbar {
        border-radius: 15%;
        max-height: 50px;
        max-width: 50px;
        margin: 0 auto;
        display: block;
        margin-bottom: 1em;
    }
    .mat-drawer-inner-container {
        overflow-x: hidden !important;
        width: 100%;
    }
}

.button-circle-identity {
    background: #3C414F;
    border-radius: 30px;
    color: white !important;
    height: 42px !important;
    padding: 0 13px !important;

    .mat-elevation-z2 {
        box-shadow: none;
    }
}

.button-circle-identity-mobile {
    background: transparent;
    border-radius: none;

    .mat-elevation-z2 {
        box-shadow: none;
    }
}

.button-circle-identity:hover {
    background: #3C414F !important;
    color: white !important;
}


.size-logo-menu {
    width: 50%;
}


.size-img-sidebar{
    width: 35%;
}

.side-bar-fixed {
    border-bottom: 1px solid #2345451c;
    z-index: 2;
    box-shadow: 0 0px 6px #23454573;
    width: 330px; 
    height: 100%; 
    background: white; 
    position: absolute;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.side-bar-fixed .mat-expansion-panel {
    box-shadow: none !important;
    padding-bottom: 0 !important;
}

.side-bar-fixed .mat-expansion-panel-body {
    display: flex;
    flex-direction: column;
}

.side-bar-fixed .mat-expansion-panel-body > a {
    text-decoration: none !important;
    color: #48657b80 !important;
    padding: 10px 0;
    height: 10px;
    font-size: 0.875rem;
}

.side-bar-fixed-link {
    text-decoration: none !important;
    color: #48657b !important;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.side-bar-fixed-link .mat-list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.side-bar-fixed > a {
    padding: 10px 25px;
    height: 3.5em;
}

.side-bar-fixed-link-subitem {
    text-decoration: none !important;
    color: #48657b !important;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 0.875rem;
}

.side-bar-fixed-link-subitem .mat-list-item-content {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.item-without-link {
    text-decoration: none !important;
    color: #48657b !important;
}

.side-bar-fixed-item .exp-panel .mat-expansion-panel-header {
    padding: 0 15px;
    font-size: 1rem;
}

.side-bar-fixed-item .exp-panel .mat-expansion-panel-header .textAlign {
    align-self: center;
    font-size: 0.875rem;
}

.side-bar-fixed-item .exp-panel .mat-expansion-panel-header .first-level {
    display: flex;
}

.side-bar-fixed-item .exp-panel .first-level-submenu-item {
    font-size: 14px;
    color: #48657b80 !important;
}

.content-card {
    align-self: center;
    width: 80%; 
}

.card-sub {
    margin: 0 !important;
}

.size-logo-menu-start {
    width: 35%;
}

.card-center {
    justify-content: center;
}

.side-bar-fixed .mat-list-base .mat-divider{
    border-top-color: #48657b47;
    margin: 1rem;
    max-width: 6rem;
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
}

.side-bar-fixed .mat-list-base .mat-list-item.first-level {
    height: 3.5em;
}

.mat-menu-panel {
    max-width: 350px !important;
}

.side-menu-radius {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.side-bar-fixed-item .mat-list-item .mat-list-item-content  {
    font-size: 0.875rem;
}

.side-bar-fixed .mat-expansion-panel-body .mat-tree .mat-tree-node {
    font-size: 0.875rem;
}

.btn-check-group .mat-radio-button {
    padding: 10px;
}

.container-check-group {
    display: inline-grid;
}

.status-action-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    place-items: center;
    padding: 5px;
    font-weight: 700;
}

.status-action {
    padding: 5px;
    height: 50px;
    text-align: center;
    place-items: center;
}

.status-action-warning {
    color: $warning;
}

.status-action-deactivate {
    color: $error;
}

.status-action-activate {
    color: $success;
}

.status-action-to-complete {
    color: #1cb1fe;
}

.link-doc-number { 
    font: 400 14px / 20px "IBM Plex Sans", sans-serif;
}

.td-center {
    text-align: center !important;
    justify-content: center;
}

.custom-drag-table th[cdkDrag] {
    cursor: grab;
}
  
.custom-drag-table th[cdkDrag].cdk-drag-dragging {
    cursor: grabbing;
}
  
.custom-drag-table .cdk-drag-preview {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    border: 1px solid #00aec7 !important;
    background-color: rgba(0, 174, 199, 0.8) !important;
    color: white !important;
    opacity: 0.9 !important;
}
  
.custom-drag-table .cdk-drag-placeholder {
    background-color: rgba(0, 174, 199, 0.2) !important;
    border: 1px dashed #00aec7 !important;
    opacity: 0.5 !important;
}
  
.custom-drag-table .cdk-drag * {
    opacity: 0.7;
}
  
.custom-drag-table .cdk-drag-dragging th {
    border: 1px solid #00aec7 !important;
    background-color: rgba(0, 174, 199, 0.1) !important;
}
  
.custom-drag-table .cdk-drag-dragging th:not(.cdk-drag-preview) {
    opacity: 0.7 !important;
}

.container-btn-crud {
    text-align: center;
    margin-top: 10px;
}